


const getBook = (path) => {
  if (!path || path.length===0) {
    return []
  }
  const regex = /\/([a-zA-Z0-9-]+)\/content\//;
  const match = path.match(regex)
  if (match && match[1]) {
    return match[1]
  } else {
    return ""
  }
}


const calculateManualVersions = (path, versions) => {
  if (!versions) {
    return []
  }
  if (!path || path.length===0) {
    return []
  }
  path=path.toLowerCase()
  if (path.includes("webhelp")) {
    const match = path.match(/artifacts\/([^/]+)\//)
    if (match && match[1]) {
      var version = match[1]
    }
    return [version]
  } else {
    return []
  }
}



const allReleaseVersions = (data, majorRelease) => {
  let jsonArray= []
  jsonArray = data.edges.reduce((acc, curr) => {
    curr.node.amp.forEach((amp) => acc.push( curr.node.aep.version + '.' + amp.version ))
  return acc;
  },jsonArray)

return jsonArray;
}

const getAllComponents = (data, property, value) => {
  const uniqueComponentsObject = data.edges.reduce((acc, item) => {
    if (item.node.frontmatter && item.node.frontmatter.component && item.node.frontmatter[property] === value) {
      acc[item.node.frontmatter.component] = item.node.frontmatter.component;
    }
    return acc;
  }, {});
  const sortedEntries = Object.entries(uniqueComponentsObject).sort((a, b) => a[0].localeCompare(b[0]));
  const sortedObject = Object.fromEntries(sortedEntries);

  return sortedObject;
};




export { calculateManualVersions, getBook, allReleaseVersions, getAllComponents }
